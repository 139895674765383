// @flow
import * as React from "react";
import type {Product} from "../../../models/Product";
import "./NscDealerSearch.scss";
import {currencyConverter} from "../../../helper/currency";
import {DISTRIBUTION} from "../../../models/DistributionChannels";

type Props = {
    product: Product,
    availableOnOtherDealersCount: number,
    showDealerList: boolean,
    setDealerList: boolean => void,
    t: string => string,
    showPrices: boolean,
    disclaimer: string,
    brand: string,
};

export const NscDealerSearch = (props: Props) => {
    let selectedOption = DISTRIBUTION.NONE;
    if (props.product.distributionChannels.mounting) {
        selectedOption = DISTRIBUTION.MOUNTING;
    }
    if (props.product.distributionChannels.pickup) {
        selectedOption = DISTRIBUTION.PICKUP;
    }

    const isMounting = selectedOption === DISTRIBUTION.MOUNTING;

    const renderPrice = (props: Props) => {
        const {
            recommendedRetailPrice,
            unitPrice,
            price
        } = props.product;
        const priceClass = `hpm-price ${recommendedRetailPrice ? "hpm-stroke hpm-pushLeft" : ""}`;
        return <div className="hpm-nscPriceBox">
	{recommendedRetailPrice &&
	<div className={priceClass}>{currencyConverter(recommendedRetailPrice)}</div>
            }
	<div className="hpm-retailPrice">
		{price ? props.t("productDetail.rrpPrice", {price: currencyConverter(price)}) : props.t("productDetail.priceOnRequest")}
	</div>
	<div className="hpm-info">
		{price && props.t("productDetail.inclVAT")}
		{isMounting &&
		<React.Fragment> {props.t("productDetail.and")} {props.t("productDetail.inclMount")}</React.Fragment>}
		{unitPrice && <span className={"hpm-unitPrice"}>
			<small>({currencyConverter(unitPrice.pricePerUnit)} / {unitPrice.measuringUnit})</small>
		</span>}
	</div>
        </div>;
    };

    return !!props.availableOnOtherDealersCount && <div className={"NscDealerSearch"}>
	{props.showPrices && renderPrice(props)}
	<div className="hpm-dealerCount">
		<span>{props.t("nsc.availableGarages", {count: props.availableOnOtherDealersCount})}</span>
	</div>
	<div className="hpm-actions">
		<button type={"button"} className={"hpm-cartButton"} onClick={() => props.setDealerList(true)}>
			{props.t("nsc.cartButton", {brand: props.brand})}
		</button>
	</div>
	{props.showPrices &&
	<div className="hpm-priceDisclaimer" dangerouslySetInnerHTML={{__html: props.disclaimer}}/>}
    </div>;
};
