// @flow
import React from "react";
import "./BrowserNotSupported.scss";
import {withTranslation} from "react-i18next";
import type {translate} from "../../i18next";
import i18n from "../../i18next";

export type BrowserNotSupportedProps = {
	t: translate
};

function BrowserNotSupported(props: BrowserNotSupportedProps) {
	i18n.changeLanguage(navigator.language || navigator.userLanguage || "en");

	return <div className="hpm-browserNotSupported">

		<div className="hpm-notSupportedText">
			{props.t("cookiesDisabled.text")}
		</div>
	</div>;
}

export default withTranslation()(BrowserNotSupported);
