/* eslint-disable no-console */
// @flow
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import {createRoot} from "react-dom/client";
import {Provider} from "react-redux";
import store from "./store";
import "./styles/style.scss";
import LangSwitchProvider from "./container/LangSwitchProvider";
import "./App.scss";
import App from "./App";
import BrowserNotSupported from "./components/BrowserNotSupported/BrowserNotSupported";
import ToastifyContainer from "./container/ToastifyContainer";
import RootNodeProvider from "./helper/RootNodeProvider";
import PreviewApp from "./preview/App";
import DealerFinderApp from "./dealerfinder/App";
import CookiesDisabled from "./components/BrowserNotSupported/CookiesDisabled";

const shouldRenderInstantly = RootNodeProvider.getRootNode() && !(RootNodeProvider.getRootNode().dataset.delayed !== undefined);
const isInternetExplorer = !!document.documentMode;

const checkStorageAllowance = () => {
    let storage;
    try {
        storage = window["localStorage"];
        let x = "__storage_test__";
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    } catch (e) {
        return e instanceof window.DOMException && (e.code === 22 || e.code === 1014 || e.name === "QuotaExceededError" || e.name === "NS_ERROR_DOM_QUOTA_REACHED") && (storage && storage.length !== 0);
    }
};

const renderCookiesDisabledFallback = () => {
    return <Provider store={store}>
	<LangSwitchProvider>
		<CookiesDisabled/>
	</LangSwitchProvider>
    </Provider>;
};

const render = () => {
    const root = createRoot(RootNodeProvider.getRootNode());

    if (!checkStorageAllowance()) {
        root.render(renderCookiesDisabledFallback());
    } else {
        root.render(
	<Provider store={store}><LangSwitchProvider>
		<ToastifyContainer/>
		{isInternetExplorer ? <BrowserNotSupported/> : <App/>}
	</LangSwitchProvider>
	</Provider>
        );
    }
};

if (shouldRenderInstantly) {
    render();
} else {
    window["renderHpm"] = render;
}

if (RootNodeProvider.getPreviewRootNode()) {
    const previewRootNode = createRoot(RootNodeProvider.getPreviewRootNode());

    if (!checkStorageAllowance()) {
        previewRootNode.render(renderCookiesDisabledFallback());
    } else {

        previewRootNode.render(
	<Provider store={store}>
		{isInternetExplorer
                    ? <BrowserNotSupported/>
                    : <PreviewApp/>
                }
	</Provider>
        );
    }
}

if (RootNodeProvider.getDealerFinderRootNode()) {
    const previewRootNode = createRoot(RootNodeProvider.getDealerFinderRootNode());
    if (!checkStorageAllowance()) {
        previewRootNode.render(renderCookiesDisabledFallback());
    } else {
        previewRootNode.render(
	<Provider store={store}>
		<LangSwitchProvider>
			<ToastifyContainer/>
			{isInternetExplorer
                        ? <BrowserNotSupported/>
                        : <DealerFinderApp/>
                    }
		</LangSwitchProvider>
	</Provider>
        );
    }
}
